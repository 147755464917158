import React from 'react'
import styled from 'styled-components'
import { GoogleMap, useLoadScript, InfoBox } from '@react-google-maps/api'

import mapStyles from 'assets/map-styles'
import systemElfaLogo from 'assets/icons/elfa-creating-space.svg'

const StyledMarkerWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  width: 104px;
  height: 128px;
  clip-path: polygon(100% 0, 100% 60%, 50% 100%, 0 60%, 0 0);
`

const StyledLogotype = styled.img`
  width: 84px;
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
`

const ElfaMarker = () => {
  return (
    <StyledMarkerWrapper>
      <StyledLogotype src={systemElfaLogo} />
    </StyledMarkerWrapper>
  )
}

const StyledGoogleMapWrapper = styled.div`
  /* this wrapper styled-component is used only to add below css rule to GoogleMap */
  /* hide 'use ctrl + scroll to zoom the map' overlay on desktop */
  @media (min-width: 992px) {
    .gm-style-moc {
      display: none;
    }
  }
`

type Props = {
  pinPosition: {
    lat: number
    lng: number
  }
  mapContainerStyle: {
    width: string
    height: string
  }
}

const Map: React.FC<Props> = ({ pinPosition, mapContainerStyle }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyD3tIRMMIWZPgH04GVCHEb9-UljvPvF8PY',
  })

  const renderMap = () => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that

    return (
      <StyledGoogleMapWrapper>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={pinPosition}
          zoom={12}
          options={{ styles: mapStyles }}
        >
          <InfoBox
            //   set offset to position anchor at bottom center of the infobox instead of default top left
            options={{ pixelOffset: new google.maps.Size(-74, -183) }}
            // company address coordinates
            position={new google.maps.LatLng(pinPosition.lat, pinPosition.lng)}
          >
            <ElfaMarker />
          </InfoBox>
        </GoogleMap>
      </StyledGoogleMapWrapper>
    )
  }

  if (loadError) {
    return <div>Przepraszamy, mapa nie mogła zostać teraz załadowana.</div>
  }

  return isLoaded ? renderMap() : <div>Wczytywanie mapy...</div>
}

export default React.memo(Map)
