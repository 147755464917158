import React from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

import SideBySide from 'components/shared/side-by-side'
import ContactForm from 'components/contact-form'
import Map from 'components/map'

import Header from 'components/header'
import ContactOverlay from 'components/pages/contact/contact-overlay'

import useBreakpoint from 'hooks/useBreakpoint'

import { KontaktPageQuery } from 'types/graphql'

const Kontakt: React.FC<PageProps<KontaktPageQuery>> = ({ data }) => {
  const PAGE = data?.wpPage?.contactPage
  const PAGE_SEO = data?.wpPage?.seo!

  const { lg } = useBreakpoint()

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />

      <Header
        image={{
          src: PAGE?.contactPageS1Img?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.contactPageS1Img?.altText!,
        }}
        title={PAGE?.contactPageS1Title!}
        subtitle={PAGE?.contactPageS1Paragraph!}
        page="kontakt"
      />
      <ContactOverlay
        title={PAGE?.contactPageS2Title!}
        address={PAGE?.contactPageS2Contact?.contactPageS2ContactAddress!}
        email={PAGE?.contactPageS2Contact?.contactPageS2ContactEmail!}
        phone={PAGE?.contactPageS2Contact?.contactPageS2ContactPhone!}
      />
      <SideBySide addMarginBottom>
        <Map
          pinPosition={{
            lat: Number(PAGE?.contactPageS3Lat!),
            lng: Number(PAGE?.contactPageS3Lng!),
          }}
          mapContainerStyle={{
            width: '100%',
            height: lg ? '100%' : '80vh',
          }}
        />
        <ContactForm />
      </SideBySide>
    </Layout>
  )
}

export default Kontakt

export const query = graphql`
  query KontaktPage {
    wpPage(slug: { regex: "/kontakt/" }) {
      seo {
        ...WpSEO
      }
      contactPage {
        contactPageS1Title
        contactPageS1Paragraph
        contactPageS1Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
        contactPageS2Title
        contactPageS2Contact {
          contactPageS2ContactAddress
          contactPageS2ContactEmail
          contactPageS2ContactPhone
        }
        contactPageS3Lat
        contactPageS3Lng
      }
    }
  }
`
