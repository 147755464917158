import React from 'react'
import styled from 'styled-components'

import media from 'styles/media'

const StyledWrapper = styled.div`
  padding: 40px 0 60px 0;
  background-color: ${({ theme }) => theme.colors.pewterblue};
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${media.lg.min} {
    padding: 60px 0 178px 0;
    position: absolute;
    background-color: rgba(139, 166, 169, 0.9);
    width: 100%;
    transform: translateY(-100%);
  }
`

const StyledHeading = styled.h3`
  margin-bottom: 30px;

  ${media.lg.min} {
    font-size: 36px;
  }
`

const StyledSubheading = styled.p`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;

  ${media.lg.min} {
    font-size: 24px;
  }
`

const StyledContactItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${media.lg.min} {
    flex-direction: row;
    gap: 60px;
  }
`

const StyledContactItem = styled.div`
  margin-bottom: 30px;

  ${media.lg.min} {
    width: 250px;
    margin-bottom: 0;
  }
`

const StyledAddress = styled.p`
  line-height: 28px;

  ${media.lg.min} {
    font-size: 20px;
  }
`

const StyledEmailLink = styled.a`
  color: ${({ theme }) => theme.colors.white};

  ${media.lg.min} {
    font-size: 20px;
  }
`

const StyledPhoneLink = styled.a`
  color: ${({ theme }) => theme.colors.white};

  ${media.lg.min} {
    font-size: 20px;
  }
`

type Props = {
  title: string
  address: string
  email: string
  phone: string
}

const ContactOverlay: React.FC<Props> = ({ title, address, email, phone }) => {
  return (
    <StyledWrapper>
      <StyledHeading dangerouslySetInnerHTML={{ __html: title }} />
      <StyledContactItemsWrapper>
        <StyledContactItem>
          <StyledSubheading>Adres</StyledSubheading>
          <StyledAddress dangerouslySetInnerHTML={{ __html: address }} />
        </StyledContactItem>
        <StyledContactItem>
          <StyledSubheading>E-mail</StyledSubheading>
          <StyledEmailLink
            href={`mailto:${email}`}
            dangerouslySetInnerHTML={{ __html: email }}
          />
        </StyledContactItem>
        <StyledContactItem>
          <StyledSubheading>Telefon</StyledSubheading>
          <StyledPhoneLink
            href={`tel:${phone}`}
            dangerouslySetInnerHTML={{ __html: phone }}
          />
        </StyledContactItem>
      </StyledContactItemsWrapper>
    </StyledWrapper>
  )
}

export default ContactOverlay
